import $ from 'jquery';
import 'selectize';

type SelectizeOptions = {
  plugins: string[],
  dropdownParent: string,
  allowEmptyOption: boolean,
  load: any,
  valueField: string,
  labelField: string,
  searchField: string
};

export default class Selectize {
  select: JQuery<HTMLElement>;
  options: SelectizeOptions = {
    plugins: null,
    dropdownParent: 'body',
    allowEmptyOption: true,
    load: null,
    valueField: 'id',
    labelField: 'value',
    searchField: 'value'
  };

  constructor(select: HTMLSelectElement) {
    if(select.multiple) {
      this.options.plugins = ['remove_button'];
    }

    if(select.dataset.url) {
      this.options.load = this.load;
    }

    this.select = $(select).selectize(this.options);
  }

  private load = (query: string, callback: any) => {
    if (!query.length) return callback();

    $.ajax({
      url: this.select[0].dataset.url,
      type: 'GET',
      data: {
        q: {
          [this.select[0].dataset.query]: query
        }
      },
      error: function() {
        callback();
      },
      success: function(response) {
        callback(response);
      }
    });
  }

  static init(scope: object = document) {
    $(scope).find('select:not(.browser-default)').each((i, select) => {
      new Selectize(select as HTMLSelectElement);
    });
  }
}
