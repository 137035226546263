// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import '@popperjs/core';
import 'bootstrap';
import Tablesaw from 'tablesaw';
import $ from 'jquery';

// Support component names relative to this directory:
var componentRequireContext = require.context('../components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

Rails.start();
ReactRailsUJS.detectEvents();

import '../../../vendor/assets/ulpgcds/js/ulpgcds';

import Selectize from '../components/selectize.ts';

window.addEventListener('DOMContentLoaded', (event) => {
  window.$ = $;

  Tablesaw.init();
  Selectize.init();
});
